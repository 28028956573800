import { defaultDeliveryDate } from 'store/constant'
import moment from 'moment'

export const danhXungKH = [
    { value: 'Anh', label: 'Anh' },
    { value: 'Chị', label: 'Chị' },
    { value: 'Cô', label: 'Cô' },
    { value: 'Chú', label: 'Chú' },
    { value: 'Bác', label: 'Bác' },
    { value: 'Ông', label: 'Ông' },
    { value: 'Bà', label: 'Bà' },
]

export const INITIAL_VALUE = {
    orderId: '',
    deliveryId: '',
    order_number: '',
    // customer
    KHId: '',
    danhXung: '',
    name: '',
    phone: '',
    address: '',
    addressId: '',
    recieverDanhXung: '',
    recieverName: '',
    recieverPhone: '',
    recieverAddress: '',
    addressIds: [], // chi dung client
    customerLink: '',
    tagName: [],
    deliveryDate: moment()
        .add(defaultDeliveryDate, 'h')
        .format('HH:mm DD/MM/YYYY'),
    note: '',
    nhomBenh: '',
    thongTinNguoiBenh: '',
    nguoiGioiThieu: null,
    products: [],
    CSKH: '',
    status: 'new',
    // kenh mua hang
    kenhMuaHang: '',
    kenhMuaHangId: null,
    // files
    uploadedFiles: [],
    files: [],
    images: [],
    // payment
    payment: 'cod',
    isHidePrice: false,
    isPayment: false,
    privateNote: '',
    transporter: '',
    discount: 0,
    ship: 0,
    shipDiscount: 0,
    isFreeship: false,
    isRxPrescription: false,
}

export const paymentMethods = [
    { value: 'cod', label: 'Tiền mặt' },
    { value: 'ebanking', label: 'Chuyển khoản' },
]
