import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { ButtonBase, Avatar } from '@mui/material'

// project imports
import config from 'config'
import Logo from '../../../ui-component/Logo'
import { SET_MENU } from 'store/actions'
import { IconMenu2 } from '@tabler/icons'
import Customization from '../../Customization'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const leftDrawerOpened = useSelector((state) => state.customization.opened)
    const dispatch = useDispatch()

    return (
        <>
            <ButtonBase
                disableRipple
                component={Link}
                to={config.defaultPath}
                sx={{
                    display: { xs: 'none', md: 'block' },
                }}
            >
                <Logo />
            </ButtonBase>

            <ButtonBase
                sx={{
                    borderRadius: '12px',
                    overflow: 'hidden',
                    display: { xs: 'block', md: 'none' },
                }}
            >
                <IconMenu2
                    stroke={1.5}
                    size="1rem"
                    onClick={() =>
                        dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
                    }
                />
            </ButtonBase>
        </>
    )
}

export default LogoSection
