// assets
import { IconBookmark } from '@tabler/icons'

// constant
const icons = {
    IconBookmark,
}

// ==============================|| VienPhi MENU ITEMS ||============================== //

const DatHang = {
    id: 'dat-hang',
    title: 'Đặt hàng',
    type: 'group',
    children: [
        {
            id: 'dat-hang',
            title: 'Đặt hàng',
            icon: icons.IconBookmark,
            type: 'item',
            url: '/dat-hang',
            breadcrumbs: false,
        },
    ],
}

export default DatHang
