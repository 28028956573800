import * as React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@mui/material'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import MenuList from './MenuList'
import LogoSection from '../LogoSection'
import MenuCard from './MenuCard'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BrowserView, MobileView } from 'react-device-detect'
const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} )`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default function MiniDrawer({ drawerOpen, drawerToggle, window }) {
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
    const handleHamburger = () => {
        console.log('click hambergur')
        drawerToggle()
    }
    const drawer = (
        <>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd
                            ? 'calc(100vh - 56px)'
                            : 'calc(100vh - 80px)',
                    }}
                >
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>

            <MobileView>
                <Box>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    )

    const onMouseEnter = () => {
        console.log('hover')
        !drawerOpen && drawerToggle()
    }
    const onMouseLeave = () => {
        console.log('leave')
        drawerOpen && drawerToggle()
    }
    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? drawerWidth : 'auto',
            }}
            aria-label="mailbox folders"
        >
            <Drawer
                onMouseEnter={() => onMouseEnter()}
                onMouseLeave={() => onMouseLeave()}
                variant="permanent"
                open={drawerOpen}
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': {
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '80px',
                            paddingTop: '8px',
                        },
                    },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                open={drawerOpen}
                sx={{
                    display: { xs: drawerOpen ? 'block' : 'none', md: 'none' },
                    '& .MuiDrawer-paper': {
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.down('md')]: {
                            top: '80px',
                            paddingTop: '8px',
                        },
                    },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    )
    Sidebar.propTypes = {
        drawerOpen: PropTypes.bool,
        drawerToggle: PropTypes.func,
        window: PropTypes.object,
    }
}
