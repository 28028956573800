// theme constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320
export const defaultDeliveryDate = 3
export const debounceTimer = 500

export const colorStatus = {
    success: '#52c41a',
    processing: '#1677ff',
    default: 'default',
    error: '#ff4d4f',
    warning: '#faad14',
}

export const LOCAL_STORAGE_KEY_ORDER = 'order'

export const COPHAR_BANKING_INFO = {
    chuTaiKhoan: 'Trần Văn Tư',
    soTaiKhoan: '3680259680',
    nganHang: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam (BIDV)',
    tenNganHang: 'BIDV',
}

export const SHIP_DISCOUNT = 10000

export const ceo_id = '64ec0997b1c9db0135196d25'

export const gcsRootFolders = {
    products: 'products',
    orders: 'orders',
}
