import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useContext,
} from 'react'
import { useNavigate } from 'react-router-dom'
import * as userAPI from '../api/user.api'
import * as sessionAPI from '../api/session.api'
import { toast } from 'react-toastify'

const AuthContext = createContext()

const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingInitial, setLoadingInitial] = useState(true)
    const navigate = useNavigate()

    const loginMagic = async (token, tokenType = 'oauth') => {
        setLoading(true)
        const data = await sessionAPI.loginWithMagicToken(token, tokenType)
        setAuth(data.message)
        navigate('/dashboard')
    }

    const login = async (userName, password, checked) => {
        try {
            const data = await sessionAPI.login(userName, password, checked)
            setAuth(data.message)
            navigate('/dashboard')
        } catch (error) {
            toast(
                error.errors.length > 1
                    ? error.errors[1].msg
                    : error.errors[0].msg
            )
        }
    }

    const fetchUserDevices = async (callback) => {
        setLoading(true)
        sessionAPI
            .fetchDevices()
            .then((data) => {
                callback(data.message)
            })
            .catch((error) => {
                toast(error.message)
                setError(error)
            })
            .finally(() => setLoading(false))
    }

    const update = (values, redirect) => {
        setLoading(true)
        userAPI
            .updateUser(values)
            .then((data) => {
                setAuth(data)
                if (redirect) {
                    if (
                        data.user.isFirstTime &&
                        !data?.user?.requiretwoFactor
                    ) {
                        return navigate('/setup-team')
                    }
                    navigate('/dashboard')
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))
    }

    const logout = async () => {
        await sessionAPI.logout()
        setAuth(undefined)
        navigate('/login')
    }

    const invalidateJwtTokens = async (userId, callback) => {
        sessionAPI.invalidateAllTokens(userId).then((data) => {
            if (typeof callback !== 'undefined') {
                callback(data)
            }
        })
    }

    const reloadUserInfo = async () => {
        return userAPI
            .getCurrentUser()
            .then((data) => setAuth(data))
            .catch((error) => {})
            .finally(() => setLoadingInitial(false))
    }

    useEffect(() => {
        reloadUserInfo()
    }, [])

    const memoedValue = useMemo(
        () => ({
            auth,
            loading,
            error,
            login,
            loginMagic,
            logout,
            invalidateJwtTokens,
            fetchUserDevices,
            update,
            reloadUserInfo,
        }),
        [auth, loading, error]
    )
    return (
        <AuthContext.Provider value={memoedValue}>
            {!loadingInitial && children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider, useAuth }
