// assets
import { IconCurrencyDollar } from '@tabler/icons'

// constant
const icons = {
    IconCurrencyDollar,
}

// ==============================|| VienPhi MENU ITEMS ||============================== //

const PersonalIncome = {
    id: 'personal-income',
    title: 'Doanh số cá nhân',
    type: 'group',
    children: [
        {
            id: 'personal-income',
            title: 'Doanh số cá nhân',
            icon: icons.IconCurrencyDollar,
            type: 'item',
            url: '/personal-income',
            breadcrumbs: false,
        },
    ],
}

export default PersonalIncome
