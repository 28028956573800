import { lazy } from 'react'

// project imports
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'
import MainLayout from 'layout/MainLayout'

const ChoDongGoi = Loadable(
    lazy(() => import('../views/choDongGoi/choDongGoi'))
)

// ==============================|| MAIN ROUTING ||============================== //

const DongGoiRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/danh-sach-dong-goi',
            element: <ChoDongGoi />,
        },
    ],
}

export default DongGoiRoutes
