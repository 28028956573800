// assets
import { IconDatabaseImport } from '@tabler/icons'

// constant
const icons = {
    IconDatabaseImport,
}

// ==============================|| VienPhi MENU ITEMS ||============================== //

const NhapKho = {
    id: 'nhap-kho-group',
    title: 'Nhập kho',
    icon: icons.IconDatabaseImport,
    type: 'group',
    children: [
        {
            id: 'nhap-kho-group',
            title: 'Nhập kho',
            icon: icons.IconDatabaseImport,
            type: 'collapse',
            children: [
                {
                    id: 'thuoc',
                    title: 'Thuốc',
                    type: 'item',
                    url: '/nhap-kho/thuoc',
                    breadcrumbs: false,
                },
                {
                    id: 'nhap-kho',
                    title: 'Vật tư',
                    type: 'item',
                    url: '/nhap-kho/vat-tu',
                    breadcrumbs: false,
                },
            ],
        },
    ],
}

export default NhapKho
