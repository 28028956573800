// assets
import { IconSearch } from '@tabler/icons'

// constant
const icons = {
    IconSearch,
}

// ==============================|| VienPhi MENU ITEMS ||============================== //

const TraCuuThuoc = {
    id: 'tra-cuu-thuoc',
    title: 'Tra Cứu Thuốc',
    type: 'group',
    children: [
        {
            id: 'tra-cuu-thuoc',
            title: 'Tra Cứu Thuốc',
            icon: icons.IconSearch,
            type: 'item',
            url: '/tra-cuu-thuoc',
            breadcrumbs: false,
        },
    ],
}

export default TraCuuThuoc
