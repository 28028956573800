import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'

const CSKH = Loadable(lazy(() => import('../views/cskh/cskh')))

// ==============================|| MAIN ROUTING ||============================== //

const cskhRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/cskh',
            element: <CSKH />,
        },
    ],
}

export default cskhRoutes
