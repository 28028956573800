// assets
import { IconUser } from '@tabler/icons'

// constant
const icons = {
    IconUser,
}

// ==============================|| VienPhi MENU ITEMS ||============================== //

const CSKH = {
    id: 'cskh',
    title: 'CSKH',
    type: 'group',
    children: [
        {
            id: 'cskh',
            title: 'CSKH',
            icon: icons.IconUser,
            type: 'item',
            url: '/cskh',
            breadcrumbs: false,
        },
    ],
}

export default CSKH
