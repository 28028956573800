import axios from 'axios'

export async function loginWithMagicToken(token, tokenType) {
    try {
        const response = await axios.post('/api/login-magic', {
            token,
            tokenType,
        })
        return response.data
    } catch (err) {
        throw err.response.data
    }
}

export async function login(userName, password, checked) {
    try {
        const response = await axios.post('/api/login', {
            userName,
            password,
            checked,
        })
        return response.data
    } catch (err) {
        throw err.response.data
    }
}

export async function fetchDevices() {
    try {
        const response = await axios.get('/api/devices')
        return response.data
    } catch (err) {
        throw err.response.data
    }
}

export async function validateToken(userId, token) {
    try {
        const response = await axios.post('/api/totp/validate', {
            userId,
            token,
        })
        return response.data
    } catch (err) {
        throw err.response.data
    }
}

export async function logout() {
    const response = await axios.delete('/api/logout')
    return response.data
}

export async function invalidateAllTokens(userId) {
    const response = await axios.post('/api/invalidate-tokens', { userId })
    return response.data
}
