import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'

const DatHang = Loadable(lazy(() => import('../views/datHang/datHang')))

// ==============================|| MAIN ROUTING ||============================== //

const datHangRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/dat-hang',
            element: <DatHang />,
        },
    ],
}

export default datHangRoutes
