import { useRoutes } from 'react-router-dom'

// routes
import loginRoutes from './loginRoutes'
import MainRoutes from './MainRoutes'
import TraCuuThuoc from './traCuuThuoc.routes'
import Order from './order.routes'
import DanhSachSanPham from './danhSachSanPham.routes'
import NhapKho from './nhapKho.routes'
import CSKH from './cskh.routes'
import DatHang from './datHang.routes'
import DongGoiRoutes from './dongGoi.routes'
import ChoDongGoiRoutes from './choDongGoi.routes'
import PersonalIncomeRoutes from './personalIncome.routes'
import QrCodeRoutes from './qrCode.routes'
import SettingRoutes from './setting.routes'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        loginRoutes,
        QrCodeRoutes,
        TraCuuThuoc,
        Order,
        DanhSachSanPham,
        NhapKho,
        CSKH,
        DatHang,
        DongGoiRoutes,
        ChoDongGoiRoutes,
        PersonalIncomeRoutes,
        SettingRoutes,
    ])
}
