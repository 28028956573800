// assets
import { IconListDetails } from '@tabler/icons'

// constant
const icons = {
    IconListDetails,
}

// ==============================|| VienPhi MENU ITEMS ||============================== //

const DanhSachSanPham = {
    id: 'danh-sach-san-pham',
    title: 'Danh Sách Sản Phẩm',
    type: 'group',
    children: [
        {
            id: 'danh-sach-san-pham',
            title: 'Danh Sách Sản Phẩm',
            icon: icons.IconListDetails,
            type: 'item',
            url: '/danh-sach-san-pham',
            breadcrumbs: false,
        },
    ],
}

export default DanhSachSanPham
