import axios from 'axios'
import moment from 'moment'

const parseHocHamFromArrayToString = async (arr = []) => {
    let str = ''
    arr.forEach((item, key) => {
        str += key === 0 ? item.label : `, ${item.label}`
    })
    return str
}

export async function getCurrentUser() {
    const response = await axios.get('/api/auth')
    return response.data
}

export async function updateUser(payload) {
    const formatPayload = {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        roleIds: payload.roleIds || null,
        permissionIds: payload.permissionIds || null,
        jobTitle: payload.chucDanh ? payload.chucDanh.label : '',
        type: payload.nhomNhanVien ? payload.nhomNhanVien.label : '',
        avata: payload.avata || null,
        dob: payload.dob
            ? payload.dob.$d
                ? moment(payload.dob.$d).toISOString()
                : moment(payload.dob, 'DD/MM/YYYY').toISOString()
            : '',
        gender: payload.gioiTinh ? payload.gioiTinh.value : '',
        signature: payload.cchn || '',
        academicRank: payload.hocHam
            ? await parseHocHamFromArrayToString(payload.hocHam)
            : '',
        note: payload.ghiChu || '',
    }
    const response = await axios.post(`/api/users/update`, formatPayload)
    return response.data
}

export async function updateUserAvatar(values) {
    const response = await axios.put(`/api/user/avatar`, values, {
        withCredentials: true,
    })
    return response.data
}

export const createNewUser = async (payload) => {
    const formatPayload = {
        username: payload.username,
        password: payload.password,
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        roleIds: payload.roleIds || null,
        permissionIds: payload.permissionIds || null,
        jobTitle: payload.chucDanh ? payload.chucDanh.label : '',
        type: payload.nhomNhanVien ? payload.nhomNhanVien.label : '',
        avata: payload.avata || null,
        dob: payload.dob.$d ? moment(payload.dob.$d).toISOString() : '',
        gender: payload.gioiTinh ? payload.gioiTinh.value : '',
        signature: payload.cchn || '',
        academicRank: payload.hocHam
            ? await parseHocHamFromArrayToString(payload.hocHam)
            : '',
        note: payload.ghiChu || '',
    }
    const { data } = await axios.post('/api/users/create', formatPayload)
    return data
}

export async function getUsers({ search, page, limit }) {
    const { data } = await axios.get('/api/users', {
        params: {
            search: search,
            page: page,
            limit: limit,
        },
    })
    return data
}

export async function removeUser(id) {
    const { data } = await axios.post('/api/users/remove', {
        id: id,
    })
    return data
}

export async function uploadAvata(payload) {
    console.log('uploadAvata', payload)
    return 'uploadAvata'
}

export async function removeAvata(payload) {
    console.log('removeAvata', payload)
    return 'removeAvata'
}

export const getUserById = async ({ id }) => {
    const { data } = await axios.get(`/api/users/${id}`)
    return data
}

export const changePassword = async ({
    id,
    password,
    oldPassword,
    confirmPassword,
}) => {
    const { data } = await axios.post(`/api/users/${id}/changePassword`, {
        password,
        oldPassword,
        passwordConfirmation: confirmPassword,
    })
    return data
}
