import { lazy } from 'react'

// project imports
import Loadable from '.././ui-component/Loadable'
import MinimalLayout from '../layout/MinimalLayout'

// login option 3 routing
const AuthLogin = Loadable(
    lazy(() => import('views/pages/authentication/authentication/Login'))
)

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const loginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />,
        },
    ],
}

export default loginRoutes
