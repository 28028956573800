// assets
import { IconPackages } from '@tabler/icons'

// constant
const icons = {
    IconPackages,
}

// ==============================|| tiepdons MENU ITEMS ||============================== //

const Order = {
    id: 'danh-sach-dong-goi',
    title: 'Đóng gói Đơn hàng',
    type: 'group',
    children: [
        {
            id: 'danh-sach-dong-goi',
            title: 'Đóng gói Đơn hàng',
            type: 'item',
            url: '/danh-sach-dong-goi',
            icon: icons.IconPackages,
            breadcrumbs: false,
        },
    ],
}

export default Order
