import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from '../ui-component/Loadable'
// import RequireAuth from './RequireAuth'

const TraCuuThuoc = Loadable(
    lazy(() => import('../views/traCuuThuoc/traCuuThuoc'))
)

// ==============================|| MAIN ROUTING ||============================== //

const traCuuThuocRoutes = {
    path: '/',
    element: (
        // <RequireAuth>
        <MainLayout />
        // </RequireAuth>
    ),
    children: [
        {
            path: '/tra-cuu-thuoc',
            element: <TraCuuThuoc />,
        },
    ],
}

export default traCuuThuocRoutes
