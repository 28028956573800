import { useSelector } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'

// firebase
import { FirebaseDatabaseProvider } from '@react-firebase/database'

// routing
import Routes from './routes'

// defaultTheme
import themes from './themes'

// project imports
import NavigationScroll from './layout/NavigationScroll'
import { AuthProvider } from './contexts/auth.content'
import { OrderProvider } from 'contexts/order.context'

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization)

    return (
        <AuthProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <FirebaseDatabaseProvider>
                        <OrderProvider>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </OrderProvider>
                    </FirebaseDatabaseProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </AuthProvider>
    )
}

export default App
