import { lazy } from 'react'

// project imports
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'
import MinimalLayout from 'layout/MinimalLayout'

const DongGoi = Loadable(lazy(() => import('../views/dongGoi/dongGoi')))

// ==============================|| MAIN ROUTING ||============================== //

const DongGoiRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MinimalLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/dong-goi',
            element: <DongGoi />,
        },
    ],
}

export default DongGoiRoutes
