import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'

const Setting = Loadable(lazy(() => import('views/setting/setting')))

// ==============================|| MAIN ROUTING ||============================== //

const orderRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/setting',
            element: <Setting />,
        },
    ],
}

export default orderRoutes
