import TraCuuThuoc from './items/traCuuThuoc'
import Order from './items/order'
import DanhSachSanPham from './items/danhSachSanPham'
import NhapKho from './items/nhapKho'
import CSKH from './items/cskh'
import DatHang from './items/datHang'
import DongGoi from './items/dongGoi'
import PersonalIncome from './items/personalIncome'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        TraCuuThuoc,
        Order,
        DanhSachSanPham,
        NhapKho,
        CSKH,
        DatHang,
        DongGoi,
        PersonalIncome,
    ],
}

export default menuItems
