import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from 'react'

// toast
import { toast } from 'react-toastify'

import { LOCAL_STORAGE_KEY_ORDER } from '../store/constant'
import { useLocalStorageState } from '../hooks/useLocalStorageState'

import { INITIAL_VALUE } from 'db/orderFormData'

const OrderContext = createContext()

const useOrderForm = () => useContext(OrderContext)

const defaultData = INITIAL_VALUE
const OrderProvider = ({ children }) => {
    const [initialValues, handleUpdateForm] = useLocalStorageState({
        key: LOCAL_STORAGE_KEY_ORDER,
        value: defaultData,
    })
    // initalize order storage
    useEffect(() => {
        if (!initialValues) {
            handleUpdateForm(defaultData)
        }
    }, [])

    const handleResetForm = useCallback(() => {
        handleUpdateForm(defaultData)
    }, [handleUpdateForm])

    const updateOrderStorageValues = useCallback((value) => {
        handleUpdateForm(value)
    }, [])

    const handleAddToCart = async ({ product, variant, price, quantity }) => {
        let newProducts = []
        let toastText = 'Đã thêm'
        const index = initialValues.products.findIndex(
            (item) => item.thuocId._id === product.thuocId
        )
        if (index > -1) {
            if (quantity == 0) {
                newProducts = initialValues.products.filter(
                    (item) => item.thuocId._id !== product.thuocId
                )
                toastText = 'Đã xóa'
            } else {
                newProducts = initialValues.products.map((item) =>
                    item.thuocId._id === product.thuocId
                        ? {
                              ...item,
                              variants: product.variants,
                              variant,
                              quantity,
                              price,
                          }
                        : item
                )
                toastText = 'Đã cập nhật'
            }
        } else {
            if (!quantity) {
                toast.warning('Số lượng cần lớn hơn 0')
                return
            }
            newProducts = [
                ...initialValues.products,
                {
                    thuocId: product,
                    variants: product.variants,
                    variant,
                    quantity,
                    price,
                    discount: 0,
                    discountType: 'currency',
                    images: [],
                    ingredients: product.ingredients || '',
                    lieDung: {},
                    name: product.name,
                    note: '',
                    usage: product.usage || '',
                },
            ]
        }
        updateOrderStorageValues({ ...initialValues, products: newProducts })
        toast.success(toastText)
    }

    const memoedValue = useMemo(
        () => ({
            values: initialValues,
            handleResetForm,
            updateOrderStorageValues,
            handleAddToCart,
        }),
        [initialValues]
    )
    return (
        <OrderContext.Provider value={memoedValue}>
            {children}
        </OrderContext.Provider>
    )
}

export { OrderProvider, OrderContext, useOrderForm }
