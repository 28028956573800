import { lazy } from 'react'

// project imports
import Loadable from '../ui-component/Loadable'
import MainLayout from 'layout/MainLayout'

const QrCodeCreate = Loadable(
    lazy(() => import('views/sample-page/createQrCode'))
)

const QrCodeRoutes = {
    path: '/qrcode/create',
    element: <QrCodeCreate />,
}

export default QrCodeRoutes
