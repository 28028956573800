import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Divider, List, Typography } from '@mui/material'

// project imports
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //
const ListItemBody = ({ config }) => {
    return (
        <>
            <ListItemIcon>{config.icon}</ListItemIcon>
            <ListItemText primary={config.title} />
        </>
    )
}

const MenuItem = ({ config }) => {
    return (
        <ListItem button>
            <ListItemBody config={config} />
        </ListItem>
    )
}
const ExpandableMenuItem = ({ config }) => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <div component="nav">
            <ListItem button onClick={handleClick}>
                <ListItemBody config={config} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Menu items={config.items} />
            </Collapse>
        </div>
    )
}
const NavGroup = ({ item }) => {
    const theme = useTheme()
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />
            default:
                return (
                    <Typography
                        key={menu.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Menu Items Error
                    </Typography>
                )
        }
    })

    return (
        <>
            {/* <List
                subheader={
                    item.title && (
                        <Typography
                            variant="caption"
                            sx={{ ...theme.typography.menuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.title}
                            {item.caption && (
                                <Typography
                                    variant="caption"
                                    sx={{ ...theme.typography.subMenuCaption }}
                                    display="block"
                                    gutterBottom
                                >
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            > */}
            {items}
            {/* </List> */}

            {/* group divider */}
            {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
        </>
    )
}

NavGroup.propTypes = {
    item: PropTypes.object,
}

export default NavGroup
