import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'

const DanhSachSanPham = Loadable(
    lazy(() => import('../views/danhSachSanPham/danhSachSanPham'))
)

// ==============================|| MAIN ROUTING ||============================== //

const danhSachSanPhamRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/danh-sach-san-pham',
            element: <DanhSachSanPham />,
        },
    ],
}

export default danhSachSanPhamRoutes
