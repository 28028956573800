import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from '../ui-component/Loadable'
import RequireAuth from './RequireAuth'

const NhapKho = Loadable(lazy(() => import('../views/nhapKho/nhapKho')))
const NhapKhoVatTu = Loadable(
    lazy(() => import('../views/nhapKhoVatTu/nhapKhoVatTu'))
)

// ==============================|| MAIN ROUTING ||============================== //

const nhapKhoRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/nhap-kho/thuoc',
            element: <NhapKho />,
        },
        {
            path: '/nhap-kho/vat-tu',
            element: <NhapKhoVatTu />,
        },
    ],
}

export default nhapKhoRoutes
